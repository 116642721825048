<template>
  <Layout>
    <div class="main-finance">
      <!-- 广告图 -->
      <div class="base-width">
        <Banner class="img" :list="bannerArr" height="4.92rem" v-ani.fade-up />
      </div>
      <!-- 大图加列表 -->
      <div class="md-finance-1">
        <div class="base-width c-card">
          <ImgRatio class="c-card" :src='require("@/assets/images/finace-1.jpg")' :width="730" :height="440"
            v-ani.fade-right />
          <div class="list">
            <template v-for="item in list">
              <div class="li flex-top" :key="item.id" v-ani.fade-up>
                <img :src="item.icon" alt="" class="icon">
                <div class="text">
                  <div class="tit s22 color-2">{{ item.title }}</div>
                  <div class="desc s16 color-4">{{ item.desc }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 合作伙伴 -->
      <template v-for="(item, index) in listArr">
        <div class="md-partner-1 text-center" :key="index" v-ani.fade-up>
          <div class="base-width c-card">
            <div class="title s26 font-medium">{{ item.title }}</div>
            <div class="desc s16 color-9" v-if="item.desc">{{ item.desc }}</div>
            <LogoList :list='item.list'></LogoList>
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
import { PartnerApi } from "@/request/api/partner";
export default {
  created() {
    IndexBannerApi({ from: 421 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerArr = data;
      }
    })
    this.getPartnerFn();
  },
  data() {
    return {
      bannerArr: [],// 轮播图列表
      list: [
        {
          id: 1, icon: require('@/assets/images/finance-1.png'), title: '缓解资金压力',
          desc: '通过平台供应链金融，为施工单位提供1-3个月的材料款垫资，促进项目的顺利施工，有效缓解施工单位的资金压力。'
        },
        {
          id: 2, icon: require('@/assets/images/finance-2.png'), title: '资金更安全',
          desc: '供应链金融服务平台的基础，是易筑平台与银行共建平台支付结算中心，采购商把钱打到平台的银行监管账户，资金更安全。'
        },
        {
          id: 3, icon: require('@/assets/images/finance-3.png'), title: '成本更低',
          desc: '采购商支付的采购款先到银行给平台开设的虚拟账户，通过银行清分到供应商和平台的实体账户，减少了二次开票的税务成本，也就是降低了采购商的采购成本，增加了利润。银行通过四流合一的交易大数据，建立风控模型，给与平台相应的供应链金融授信额度，为平台上下游企业提供供应链金融服务，可以大大降低平台企业的资金使用成本。'
        }
      ],
      listArr: [],
    };
  },
  methods: {
    getPartnerFn() {
      PartnerApi({ type: '主要合作的银行与供应链金融' }).then(res => {
        if (res.status == 200) {
          this.listArr = res.data.map(item => {
            item.children = item.list.map(childItem => {
              childItem.name = childItem.title;
              childItem.src = childItem.logo_image;
              delete childItem.logo_image;
              delete childItem.title;
              return childItem;
            })
            return item;
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.main-finance {
  padding: .4rem 0 0;
}

.md-finance-1 {
  margin-top: .4rem;

  .base-width {
    display: flex;
    padding: .4rem .3rem;
  }

  .c-pic {
    width: 7.3rem;
  }

  .list {
    margin-left: .44rem;
    margin-top: .07rem;
    flex: 1;
    overflow: hidden;

    .li {
      margin-bottom: .35rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .icon {
      width: 0.3rem;
    }

    .text {
      flex: 1;
      margin-left: .1rem
    }


    .desc {
      line-height: calc(30 / 16 * 1em);
      margin-top: 0.11rem;
    }
  }
}
</style>